import React, {FunctionComponent} from 'react';
import {useSubscription} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faExclamationCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {ToolResultProps, SingleAssetResultType, IStringMap} from '../../../types';
import {ASSETS_CHECK_SUBSCRIPTION} from '../../../graphql/subscriptions';
import PieChartMetrics from '../../pie-chart-metrics';
import './styles.scss';
import {ResultCard} from '../../result-card';
import {Accordion, AccordionItem} from '../../accordion';
import {Translate} from '../../translations';
import {ProgressBar} from '../../progressBar';

export const trackingFiles: IStringMap = {
  css: 'TOTAL_CSS_FILE_COUNT',
  javascript: 'TOTAL_JAVASCRIPT_FILE_COUNT',
  image: 'TOTAL_IMAGE_FILE_COUNT',
};

export const fileColors: IStringMap = {
  css: '#f1c40f',
  javascript: '#1abc9c',
  image: '#2980b9',
};

const AssetCheckerTool: FunctionComponent<ToolResultProps> = ({
  url,
  sessionToken,
  simpleResult,
  moreButton,
  handleScanLimitCheck,
}) => {
  const {data} = useSubscription(ASSETS_CHECK_SUBSCRIPTION, {
    variables: {url, sessionToken},
  });

  const response = (data && data.assetsCheckerTool) || {};
  const assets = response.assets || [];
  const groupAssets: any = {};
  assets.map((asset: SingleAssetResultType) => {
    let assetKey = 'Other';
    for (const fileType of Object.keys(trackingFiles)) {
      if (asset.mimeType.indexOf(fileType) > -1) {
        assetKey = fileType.toUpperCase();
        break;
      }
    }

    if (!groupAssets[assetKey]) {
      groupAssets[assetKey] = [];
    }

    groupAssets[assetKey].push(asset);
  });

  if (response.url && assets.length === 0) {
    if (simpleResult) return null;
    return <Translate name="NO_RESULTS_ASSETS_CHECKER" />;
  }

  let finalSize = 0;

  assets.map((asset: SingleAssetResultType) => {
    finalSize += asset.size;
  });

  const checkSize = finalSize / 1000000;

  if (response._error && response._error.code === 'UNAUTH_USER_SCANS_LIMIT_REACHED') {
    handleScanLimitCheck && handleScanLimitCheck(true);
  }

  return !response.url && !response._error ? (
    <div className="text-center">
      <ProgressBar />
    </div>
  ) : !response._error ? (
    <div className="row">
      <div className="col-md-12">
        <ResultCard
          title={
            <>
              {moreButton}
              <Translate name="ASSETS_CHECKER_RESULT_TITLE" />
            </>
          }
          items={[
            {
              description: (
                <PieChartMetrics
                  chartData={assetsChartData(assets)}
                  labels={[
                    {
                      text: 'TOTAL_DOWNLOADED_SIZE',
                      value: `${(response.totalSize && (response.totalSize / 1000000).toFixed(2)) || 0} MB`,
                    },
                    {
                      text: 'TOTAL_ASSETS_COUNT',
                      value: assets.length.toString(),
                      color: '',
                    },
                  ].concat(
                    Object.keys(trackingFiles).map(fileType => {
                      let finalSize = 0;
                      assets.map((asset: SingleAssetResultType) => {
                        if (asset.mimeType && asset.mimeType.indexOf(fileType) > -1) {
                          finalSize += asset.size;
                        }
                      });
                      return {
                        text: trackingFiles[fileType],
                        value: `${(finalSize / 1000000).toFixed(2)}MB`,
                        color: fileColors[fileType],
                      };
                    }),
                    (() => {
                      let finalSize = 0;
                      assets.map((asset: SingleAssetResultType) => {
                        for (const fileType of Object.keys(trackingFiles)) {
                          if (asset.mimeType.indexOf(fileType) > -1) {
                            return;
                          }
                        }

                        finalSize += asset.size;
                      });
                      return [
                        {
                          text: 'TOTAL_OTHER_FILES_COUNT',
                          color: '#34495e',
                          value: `${(finalSize / 1000000).toFixed(2)}MB`,
                        },
                      ];
                    })(),
                  )}
                />
              ),
            },
          ]}
          message={
            <>
              {checkSize > 5 && (
                <div className="text-left t_color3 d-flex">
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faExclamationCircle} style={{color: 'red'}} />
                  </div>
                  <div className="f_size_14">
                    <Translate name="ASSETS_5_MORE_RED" />
                  </div>
                </div>
              )}
              {checkSize >= 3 && checkSize <= 5 && (
                <div className="text-left t_color3 d-flex">
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'yellow'}} />
                  </div>
                  <div className="f_size_14">
                    <Translate name="ASSETS_3_5_YELLOW" />
                  </div>
                </div>
              )}
              {checkSize >= 0.5 && checkSize <= 3 && (
                <div className="text-left t_color3 d-flex">
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}} />
                  </div>
                  <div className="f_size_14">
                    {checkSize >= 0.5 && checkSize <= 2 ? (
                      <Translate name="ASSETS_05_2_GREEN" />
                    ) : (
                      <Translate name="ASSETS_2_3_GREEN" />
                    )}
                  </div>
                </div>
              )}
            </>
          }
        />
      </div>
      {!simpleResult && (
        <div className="col-md-12">
          <ResultCard
            title="ALL_SCANNED_ASSETS"
            items={[
              {
                description: (
                  <Accordion>
                    {Object.keys(groupAssets).map(assetKey => (
                      <AccordionItem key={assetKey} title={`${assetKey} (${groupAssets[assetKey].length})`}>
                        <table className="table table-borderless">
                          <tbody>
                            {groupAssets[assetKey].map((asset: SingleAssetResultType) => (
                              <tr key={asset.url + assetKey}>
                                <td style={{width: 50, padding: 5}}>
                                  <span className="badge badge-light">{asset.size / 1000}KB</span>
                                </td>
                                <td className="text-truncate" style={{maxWidth: 0, padding: 5}}>
                                  <a href={asset.url} target="_blank" rel="noopener">
                                    {asset.url}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </AccordionItem>
                    ))}
                  </Accordion>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  ) : null;
};

export function assetsChartData(links?: Array<SingleAssetResultType>): Array<any> {
  if (!links) return [];
  const linkTypes: any = {};
  const linkColors: IStringMap = {};
  links.map(link => {
    let linkType = 'Type - other';
    linkColors[linkType] = '#34495e';
    for (const fileType of Object.keys(trackingFiles)) {
      if (link.mimeType.indexOf(fileType) > -1) {
        linkType = `Type - ${fileType}`;
        linkColors[linkType] = fileColors[fileType];
        break;
      }
    }

    if (!linkTypes[linkType]) {
      linkTypes[linkType] = 0;
    }

    linkTypes[linkType] += link.size;
  });

  return Object.keys(linkTypes).map(k => ({
    name: `${k} (${(linkTypes[k] / 1000000).toFixed(2)}MB)`,
    value: linkTypes[k],
    fill: linkColors[k],
  }));
}

export default AssetCheckerTool;
