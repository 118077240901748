import React from 'react';
import { ScreenShotCanvasProps } from '../../types';
import ResponsiveMockup from '../../img/Responsive-Mockup.png';

class ScreenShotsCanvas extends React.Component<
  ScreenShotCanvasProps,
  { url: string; isNotVisible: boolean }
> {
  state = {
    url: '',
    isNotVisible: false,
  };

  componentDidMount() {
    if (this.props.isDependedOnHeader) {
      /**
       * Here is the case where we check if we are on domain profile and
       * we are depending on header, so not to render again after every header changes , we check them, otherwise we are not on domain
       * page we create canvas every time as there is no any case of many renderings
       */
      if (!this.state.url) {
        this.setState(() => {
          this.updateCanvas(
            this.props.desktop,
            this.props.mobile,
            this.props.tablet
          );
          return {
            isNotVisible: !!this.props.isFixedSmallHeader,
          };
        });
      } else {
        if (this.state.isNotVisible !== !!this.props.isFixedSmallHeader) {
          this.setState({
            isNotVisible: !!this.props.isFixedSmallHeader,
          });
        }
      }
    } else {
      this.updateCanvas(
        this.props.desktop,
        this.props.mobile,
        this.props.tablet
      );
    }
  }

  componentWillReceiveProps(
    nextProps: Readonly<ScreenShotCanvasProps>,
  ): void {
    if (
      this.props.isDependedOnHeader &&
      this.props.isFixedSmallHeader !== nextProps.isFixedSmallHeader
    ) {
      this.setState({
        isNotVisible: !!nextProps.isFixedSmallHeader,
      });
    }
  }

  arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = '';
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  getImage = async (imageUrl: string | undefined): Promise<string> => {
    if (!imageUrl) return '';
    const res = await fetch(imageUrl);
    const buffer = await res.arrayBuffer();
    return `data:image/jpeg;base64,${this.arrayBufferToBase64(buffer)}`;
  };

  updateCanvas = async (
    desktop: string | undefined,
    mobile: string | undefined,
    tablet: string | undefined
  ) => {
    const canvas = document.createElement('canvas');
    canvas.width = 1167;
    canvas.height = 695;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const desktopImg = await this.getImage(desktop);
      const tabletImg = await this.getImage(tablet);
      const mobileImg = await this.getImage(mobile);

      if (desktop) {
        const desktopImage = new Image();
        desktopImage.src = desktopImg;

        desktopImage.onload = async () => {
          await ctx.drawImage(desktopImage, 128, 130, 637, 374);
        };
      }
      if (mobile) {
        const mobileImage = new Image();
        mobileImage.src = mobileImg;
        mobileImage.onload = async () => {
          await ctx.drawImage(mobileImage, 96, 470, 90, 197);
        };
      }
      if (tablet) {
        const tabletImage = new Image();
        tabletImage.src = tabletImg;
        tabletImage.onload = async () => {
          await ctx.drawImage(tabletImage, 630, 390, 443, 483);
        };
      }
      const myImage = new Image();
      myImage.src = ResponsiveMockup;
      myImage.onload = async () => {
        await ctx.drawImage(myImage, 0, 0);
        await this.setState({
          url: canvas.toDataURL(),
        });
      };
    }
  };

  render() {
    return !this.state.isNotVisible && this.state.url ? (
      <>
        <img
          style={{ maxWidth: '100%' }}
          src={this.state.url}
          alt={`${this.props.url}`}
          title={`${this.props.url}`}
        />
        <div className='d-flex justify-content-center mt-4'>
          <small className='mr-2 text-center'>Desktop - <strong>1366x768</strong></small>
          <small className='mr-2 text-center'>Netbook/Tablet - <strong>768x1024</strong></small>
          <small className='text-center'>Mobile - <strong>375x812</strong></small>
        </div>
      </>
    ) : null;
  }
}

export default ScreenShotsCanvas;
