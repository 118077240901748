import React, {FunctionComponent} from 'react';
import {useSubscription} from '@apollo/client';
import {ToolResultProps} from '../../types';
import {DEVICE_SCREENSHOTS_SUBSCRIPTION} from '../../graphql/subscriptions';
import ScreenShotsCanvas from '../device-screenshots';
import './styles.scss';
import {ProgressBar} from '../progressBar';

const DeviceScreenshotsTool: FunctionComponent<ToolResultProps> = ({url, sessionToken}) => {
  const {data} = useSubscription(DEVICE_SCREENSHOTS_SUBSCRIPTION, {
    variables: {url, sessionToken},
  });

  const response = (data && data.deviceScreenshots) || {};
  return !response.url && !response._error ? (
    <div className="text-center">
      <ProgressBar />
    </div>
  ) : !response._error ? (
    <div className="row">
      <div className="col-md-12">
        <div className="img-container">
          <ScreenShotsCanvas
            url={url}
            width={300}
            desktop={response.desktop ? response.desktop.image : null}
            tablet={response.tablet ? response.tablet.image : null}
            mobile={response.mobile ? response.mobile.image : null}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default DeviceScreenshotsTool;
