import React, {FC, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link, navigate} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../../src/components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import DeviceScreenshotsTool from '../../components/scan-tools/device-screenshots';
import AssetsCheckerTool from '../../components/scan-tools/assetsCheck';
import {Modal} from '../../components/modal';
import Button from '../../components/button';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import pageAnalyzerImg from '../../img/home-tools/page-analyzer.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const PageAnalyzer: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <Layout>
      <PageHero
        title={'Website page asset analyzer & performance tool'}
        subtitle={`Enter a URL to analyze page size, content type and file count.<br/> Type the URL to see the page size and files count`}
      >
        <DomainForm value={url} onSubmit={u => navigate(`/page-analyzer/${encodeURIComponent(u)}`)} />
      </PageHero>

      <Section>
        {isMobile ? (
          <div className="col-md-3">
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('PageAnalyzerTool', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <DeviceScreenshotsTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                              <div className="col-md-12">
                                <AssetsCheckerTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={pageAnalyzerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            The recommended maximum size of a separate image file to be published is 200 KB and the
                            average web page size should be in the range of 2-4MB.
                          </p>
                          <p>
                            Our free website page asset analyzer scans your page and detects images, script files, CSS
                            files, HTML files, and the domains the elements come from to understand how they can affect
                            page load performance.
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>The page performance checker provides a report featuring:</strong>
                          </p>
                          <ul>
                            <li>Screenshots of the website opened on 3 different devices</li>
                            <li>Shows the pie chart of assets by file types and total sizes</li>
                            <li>Provides a detailed list of files with sizes</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
            <ToolTabs active="page-analyzer" url={url} />
          </div>
        ) : (
          <div className="col-md-12 responsive-tools">
            <ToolTabs active="page-analyzer" url={url} />
            <div className="col-md-9 tool-detail">
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url}>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('PageAnalyzerTool', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <DeviceScreenshotsTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                              <div className="col-md-12">
                                <AssetsCheckerTool
                                  url={url}
                                  sessionToken={sessionToken}
                                  fullResult
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={pageAnalyzerImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            The recommended maximum size of a separate image file to be published is 200 KB and the
                            average web page size should be in the range of 2-4MB.
                          </p>
                          <p>
                            Our free website page asset analyzer scans your page and detects images, script files, CSS
                            files, HTML files, and the domains the elements come from to understand how they can affect
                            page load performance.
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>The page performance checker provides a report featuring:</strong>
                          </p>
                          <ul>
                            <li>Screenshots of the website opened on 3 different devices</li>
                            <li>Shows the pie chart of assets by file types and total sizes</li>
                            <li>Provides a detailed list of files with sizes</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Section>
    </Layout>
  );
};

export default PageAnalyzer;
